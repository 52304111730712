import { createLocalVue, shallowMount } from '@vue/test-utils';
import MainMedia from './MainMedia.vue';

jest.mock('@/router', () => ({
  currentRoute: {
    name: 'MockRoute',
  },
}));

const stubs = {
  TypeSelector: true,
  HsInput: true,
  HsButtonCircle: true,
  HsButton: {
    template: '<div class="hs-button-stub"></div>',
    props: ['disabled'],
  },
};

function mountWrapper(props) {
  const localVue = createLocalVue();

  return shallowMount(MainMedia, {
    localVue,
    stubs,
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
    },
    propsData: props,
  });
}

describe('MainMedia', () => {
  describe('selector label', () => {
    it('use the send type', () => {
      expect.assertions(1);
      const type = 'dontcare';
      const wrapper = mountWrapper({
        media: null,
        type,
        types: [],
      });
      expect(wrapper.vm.selectorLabel).toBe(type);
    });

    it('use the send type is additional, use it', () => {
      expect.assertions(1);
      const type = 'additional';
      const wrapper = mountWrapper({
        media: null,
        type,
        types: [],
      });
      expect(wrapper.vm.selectorLabel).toBe(type);
    });

    it('use the selected type as label', () => {
      expect.assertions(1);
      const typeId = 'some media type';
      const type = { id: typeId };
      const wrapper = mountWrapper({
        media: null,
        type: typeId,
        types: [],
      });
      wrapper.setData({ selectedType: type });
      expect(wrapper.vm.selectorLabel).toBe(typeId);
    });
  });

  it('default mode is "type-selection"', () => {
    expect.assertions(1);
    const wrapper = mountWrapper({
      media: null,
      type: 'dontcare',
      types: [],
    });
    expect(wrapper.vm.mode).toBe('type-selection');
  });

  it('select a media option', async () => {
    expect.assertions(1);
    const type = { id: 'some media type' };
    const wrapper = mountWrapper({
      media: null,
      type: 'dontcare',
      types: [type],
    });
    const selector = wrapper.find({ name: 'TypeSelector' });
    selector.vm.$emit('select', type);
    await wrapper.vm.$nextTick();
    expect(wrapper.vm.selectedType).toStrictEqual(type);
  });

  it('if there is a media object must jump to "set"', () => {
    expect.assertions(1);
    const wrapper = mountWrapper({
      media: { type: 'some media type' },
      type: 'dontcare',
      types: [{ id: 'some media type' }],
    });
    expect(wrapper.vm.mode).toBe('set');
  });

  it('must select type if it is the only one', () => {
    expect.assertions(1);
    const type = { id: 'single' };
    const wrapper = mountWrapper({
      media: null,
      type: 'dontcare',
      types: [type],
    });
    expect(wrapper.vm.selectedType).toStrictEqual(type);
  });

  it('must not preselect because there are more options', () => {
    expect.assertions(1);
    const wrapper = mountWrapper({
      media: null,
      type: 'dontcare',
      types: [{ id: 'a' }, { id: 'b' }],
    });
    expect(wrapper.vm.selectedType).toBeNull();
  });
});
